.Cardcss {
  width: 500%;
  color: black;
}
.Box {
  text-align: left;
  padding-left: 20%;
  padding-top: 3%;
}
.allTheBoxes {
  display: flex;
  justify-content: flex-end;
  gap: 1.4%;
  padding-top: 2%;
}
.filterRowS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1%;
}
.filterInnerRow {
  display: flex;
  gap: 10px;
  width: 70%;
}
.assessment {
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: max-width 0.3s;
}

.assessment {
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: max-width 0.3s;
}

.assessment:hover {

  white-space: normal;
  word-wrap: break-word;
}

.arrow-icon{
  color: #fe524a;
}


