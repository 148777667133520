.bottom {
  margin-top: 20px;
  margin-right: 5%;
  display: flex;
  float: right;
  gap: 20px;
}
.TablePagination {
  /* justify-items: end; */
  float: right;
  border-bottom: none;
}

.viewReportButton {
  border: 1px solid #fe524a;
  color: #fe524a;
  padding: 8px 16px;
}
.viewReportButton:hover {
  background-color: #fe524a;
  color: white;
}
.bodyStack {
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 80%; */
}


.Typography {
  color: #444343;
  text-align: center;
  font-weight: bold;
}
.filterRowStack .assessmentFilter {
  width: 16%;
  font-size: 14px;
}
.filterRowStack .monthFilter {
  width: 11%;
}
.filterRowStack .yearFilter {
  width: 11%;
}
.filterRowStack .passedFilter {
  width: 11%;
}
.filterRowStack .organizerFilter {
  width: 11%;
}
.clearFilterButton {
  padding: 8px 4px;
  font-size: 14px;
  color: #fe524a;
  border: 1px solid #fe524a;
  border-radius: 4px;
  font-weight: normal;
  /* align-items: center; */
}
.clearFilterButton:hover {
  padding: 8px 4px;
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  /* align-items: center; */
  background-color: #fe524a;
}
.clearFilterButton:disabled {
  background-color: #d9d9d9;
  color: #4f4f4f;
  font-size: 14px;
  padding: 8px 4px;
  font-weight: normal;
}
.assessmentFilter .clearIcon {
  color: #fe524a;
  font-size: small;
}
.Paper {
  width: 100%;
  overflow: hidden;
}
.Paper .TableContainer {
  max-height: 430;
}
/* .TableBody {
  font-size: 14px;
  color: #191f1c;
} */
/* .TableBody .evenRow {
  background-color: #faefef;
} */
 /* .TableBody .TableCell {
  padding: 1%;
  margin-left: 5px;
  width: 111px;;
  
}  */
.noWrap {
  font-weight: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 15%;
  /* height: ; */
  background-color: white;
  color: black;
  text-align: left;
  padding: 8px 0;
  margin-left: -12%;
  margin-top: -2%;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.MuiIconButton-root svg {

  fill: #fe524a;
}

.TablePagination .MuiIconButton-root:hover svg {
  fill: white;
}

.MuiIconButton-root:disabled svg {
  fill: #4f4f4f;
}
