.completeBox{
    width: 60%;
    padding-left: 15%;
    padding-top: 5%;
}
.textfieldscss{
    /* width: 20rem; */
    width: 20rem;
}
.sectionfieldcss{
    width: 2.5rem;
}
.selectioncss{
    display: flex;
    align-items: baseline;
}
.questionSectioncss{
  display: inline;
}
.view-mode {
  pointer-events: none;
}
.manualSelectioncss{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.configurationcss{
    display: flex;
    padding-bottom: 2%;
}
.manualSelectioncss .MuiSvgIcon-root {
    font-size: 18px;
    width: 2rem;
  }
 
  .manualSelectioncss .MuiTypography-body1 {
    font-size: 12px;
    color: #191f1c;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
 color: #191f1c;
}

/* .css-1dmew62-MuiTypography-root-MuiLink-root{
  cursor: pointer;
  text-decoration-color: #fe524a;
} */
.automationRadio .MuiSvgIcon-root{
        font-size: 18px;
        width: 2rem;
        color: #D9D9D9
  }
  
  .errorcss{
    display: flex;
    justify-content: space-between;
  }
  .QuestionDetailsBox{
       display: flex;
       justify-content:flex-start;
       align-items: center;
  }
  
.buttonsBox{
    display: flex;
    gap: 1rem;
    justify-content:flex-end;
    padding-top: 1rem; 
}
.backBox{
  display: flex;
  gap: 1rem;
  justify-content:flex-start;
  padding-top: 1rem; 
}