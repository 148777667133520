.root-container {
    width: 80% ;
    height: 70% ;
    box-shadow:  0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); ;
    position: absolute ;
    top: 50% ;
    left: 50% ;
    transform: translate(-50%, -50%) ;
}

.csButton {
   
    font-size:14px ;
    color:#FE524A ;
    border:1px solid #FE524A ;
    border-radius:4px ;
    font-weight:medium ;
    align-items:center ;
    background-color: #FFFFFF ;
  }
  
  .csButton:hover {
   
    background-color: #FE524A ;
    border-color: #fe524a;
    color : #FFFFFF ;
    cursor: pointer ;
  }
  
  .secondary-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color:black; 
  } 

.selected-item {
  color: black ;
}


.custom-select svg {
  fill: red; 
}

.custom-select .MuiSelect-outlined.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: black;
}

input[type="text"]:disabled,
input[type="email"]:disabled {
  background-color: #E0E0E0;
}
  
.Toastify__toast {

  background-color: #FAEFEF; 

  color:black;

  padding-bottom: 7%;

}
  