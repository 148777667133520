.paginationBox {
  display: flex;
  justify-content: space-between;
  margin: 0rem 1rem;
}
.optionBox {
  display: flex;
  gap: 0.5rem;
}
.responseBox {
  color: green;
  font-weight: bold;
}
.responseBoxError {
  color: red;
  font-weight: bold;
}
.headBox {
  display: flex;
  gap: 2rem;
  margin: 0rem 1rem;
}
.paginationText {
  padding-top: 1.2rem;
}
.paginationButtons {
  gap: 1.2rem;
  display: flex;
}
.paper {
  margin: 1rem;
  padding: 1rem;
}
.paperInnerBox {
  display: flex;
}
.optionsBox1 {
  display: flex;
  justify-content: space-evenly;
  gap: 4px;
}
.questionBox1 {
  min-width: 5.5rem;
}
.paperLastBox {
  display: flex;
  gap: 1.25rem;
}
.paperFinalBox {
  display: flex;
}
.actionButtons2 {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.sectionNavigation{
  height: 30px;
  min-width: 28px;
  padding: 4px 4px;
  /* padding: 4px 4px;
  width: 40px; */
}

/* button.button2 {
  padding: 8px 16px;
  font-size: 14px;
  color: #fe524a;
  border: 1px solid #fe524a;
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: 500;
  align-items: center;
}

button.button2:hover {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  align-items: center;
  background-color: #fe524a;
  cursor: pointer;
} */

/* button.navigation2 {
  background-color: #ffffff;
  border: 1px solid #fe524a;
  padding: 4px 4px;
  border-radius: 4px;
  color: #fe524a;
  align-items: center;
  width: 40px;
}

button.navigation2:hover {
  background-color: #fe524a;
  color: #ffffff;
  padding: 4px 4px;
  align-items: center;
  cursor: pointer;
  width: 40px;
}

button.navigation2:disabled {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
  padding: 4px 4px;
  align-items: center;
  width: 40px;
} */
