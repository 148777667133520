.completebox{
    width: 70%;
    padding-left: 15%;
    padding-top: 5%;
}
.insidebox{
    /* width: 70%; */
    padding-left: 17%;
    
}
.buttonBoxCss {
    display: flex;
    justify-content: flex-end;
    gap: 1.4%;
    padding-top: 2%;
}
.inputField{
    text-align: center;
    padding: 10px 40px;
    margin-right: 2%;
}
.TextField{
    padding: 10px 40px;
}