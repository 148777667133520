.DialogTitle {
  text-align: center;
  font-weight:600;
  color: #444343;
  text-transform: capitalize;
}

.Button {
  padding: 8px 16px;
  font-size: 14px;
  color: #fe524a;
  border: 1px solid #fe524a;
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: normal;
  align-items: center;
  width: 25%;
}

.Button:hover {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  align-items: center;
  background-color: #fe524a;
  border: 1px solid #fe524a;
}
