:root {
  --flagged-color: #B388E9;
  --answered-color: #93E974;
  --unanswered-color: #D9D9D9;
}

.shadow {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  /* margin-bottom: 1%; */
}

/* Candidate.css */

.flagged {
  background-color: var(--flagged-color);
}

.flagged:hover {
  background-color: var(--flagged-color);
}

.answered {
  background-color: var(--answered-color);
}

.answered:hover {
  background-color: var(--answered-color);
}

.unanswered {
  background-color: var(--unanswered-color);
}

.unanswered:hover {
  background-color: var(--unanswered-color);
}


.test-screen-body {
  flex-grow: 1;
  margin: 2% 8% 1% 8%;
  min-height: "100vh"
}

.leftGrid {
  padding: 1%;
  position: relative;
}

.sectionDetails {
  font-size: 20px;
}

.roundButton {
  border-style: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 0;
  padding: 0;
  color: #4F4F4F
}

.roundButton:hover {
  background-color: 'transparent'
}

/*AnswerTags*/

.answerTags {
  align-items: right;
  width: 100%;
  position: absolute;
  bottom: 6%;
  clear: both;
}


.answertagText {
  padding-left: 8%;
  float: right;
  font-size: 8px;
  color: .000000;
}

/*Right Grid*/
.questionTimer {
  float: right;
  padding: 2px
}

.questionTextContainer {
  margin-bottom: 1;
  min-height: 80px;
  padding: 5px;
}


.options {
  display: flex;
  justify-content: left;
  padding: 2%
}

.reviewButton {
  float: left;
}

.navigateButtons {
  float: right
}

.summaryGrid {
  border-collapse: collapse;
  width: 650px;
  padding: 3%
}

.screen-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  /* Set the height of the screen to be the full viewport height */
}

.summarySubmit {
  float: right;
  padding: 2%
}

/*Mobile View*/
@media screen and (max-width:600px) {
  .answertagText {
    padding: 0;
    float: right;
    font-size: 8px;
    color: .000000;
  }
}

.answertag {
  font-size: 8px;
  color: .000000;
  display: 'flex';
  align-items: center;
  justify-content: left;
  margin-top: 5%;
}

.flagged {
  background-color: var(--flagged-color);
}

/*Summary*/
.summary {
  margin-left: 2%;
  height: auto;
  /* margin-top:2% */
}

.summaryCell {
  border: none;
}

.summaryCellRight {

  display: flex;
  justify-content: right
}

.network{
  direction: column;
  text-align: center;
  align-items: center; 
  justify-content: center;
  display: flex;
  height: 100vh; 
}