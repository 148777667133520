/*Buttons*/
.button {
    padding: 8px 8px;
    font-size: 14px;
    color: #fe524a;
    border: 1px solid #fe524a;
    border-radius: 4px;
    font-weight: medium;
    align-items: center;
    background-color: #ffffff;
  }
  
  .button:hover {
    padding: 8px 8px;
    font-size: 14px;
    font-weight:medium;
    align-items: center;
    background-color: #fe524a;
    color: #ffffff;
    /* cursor: pointer ; */
  }
  
  .button:disabled {
    background-color: #d9d9d9;
    color: #4f4f4f;
    border: 1px #4f4f4f;
    cursor: not-allowed ;
    padding: 8px 8px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6); */
  }
  
  /*Tables*/

  .tableBody{
    width: 100%;
  }
  .tableContainer{
    width: 80%;
  }
  .tableHeader{
      font-weight: bold;
      color: #191F1C;
      background-color: #faefef;
      align-items: center;
  }
  