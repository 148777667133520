.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.MuiCircularProgress-colorPrimary {
  color: #fe524a !important;
}
.dropdown {
  margin-left: 1rem;
}

.buttonStyle {
  color: #fe524a;
  border-color: #fe524a;
  padding: 4px;
}

.disabled-button{
  background-color: "#D9D9D9";
  color: "#4F4F4F";
  border: "1px solid #4F4F4F";
  cursor: "not-allowed";
}

.buttonStyle:hover {
  background-color: #fe524a;
  color: #ffffff;
  align-items: center;
  border-color: #fe524a;
  font-weight: bold;
  padding: 4px;
  border-radius: 4px;
  cursor: "pointer"
}


.paginationBox {
  display: flex;
  justify-content: space-between;
  margin: 0rem 1rem;
}
.paginationText {
  padding-top: 1.2rem;
}
.paginationButtons {
  gap: 1.2rem;
  display: flex;
}
.paper {
  margin: 1rem;
  padding: 1rem;
}
.optionBox{
  display: flex;
   gap: 0.5rem; 
   align-items: baseline;
}
.paperOutterBox {
  display: flex;
  justify-content: space-between;
}
.paperInnerBox {
  display: flex;
  align-items: baseline;
}
.questionBox {
  min-width: 2rem;
}
.actionIcons {
  display: flex;
  border: 0px;
}
.previewicons{
  border: none;
  min-width: 34px;
  padding: 4px 4px;
}
.previewNavigation{
  height: 30px;
  min-width: 28px;
  padding: 4px 4px;
  /* padding: 4px 4px;
  width: 40px; */
}
.previewicons:disabled{
border: none;
background:none;
}
.optionsBox {
  display: flex;
  justify-content: space-evenly;
  gap: 4px;
}
.options1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding:0rem 0rem 1rem 1rem;
}
.options1 p,ul{
  margin: 0;
}
.paperInnerBox p{
  margin: 0;
}

.paperLastBox {
  display: flex;
  gap: 1.25rem;
}
.paperFinalBox {
  display: flex;
}
.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin: 1rem;
}

/* button.button1 {
  padding: 8px 16px;
  font-size: 14px;
  color: #fe524a;
  border: 1px solid #fe524a;
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: 500;
  align-items: center;
}

button.button1:hover {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  align-items: center;
  background-color: #fe524a;
  cursor: pointer;
} */

/* button.navigation {
  background-color: #ffffff;
  border: 1px solid #fe524a;
  padding: 4px 4px;
  border-radius: 4px;
  color: #fe524a;
  align-items: center;
  width: 40px;
}

button.navigation:hover {
  background-color: #fe524a;
  color: #ffffff;
  padding: 4px 4px;
  align-items: center;
  cursor: pointer;
  width: 40px;
}

button.navigation:disabled {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
  padding: 4px 4px;
  align-items: center;
  width: 40px;
} */
