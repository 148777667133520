.Onestyles {
  color: #fe524a;
  border-color: #fe524a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  font-weight: medium;
  font-size: 14px;
}

.Onestyles:hover {
  background-color: #fe524a;
  color: white;
  align-items: center;
  border-color: #fe524a;
}

/* .questionDetails {
    margin-top: 10px;
    text-align: center;
    font-Weight: bold;
    font-size: 26px;
    margin-Bottom: 10px;
} */

.discriptionLabelStyles {
  color: black;
  border-color: transparent;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  font-weight: medium;
  width: 50%;
  font-size: 17px;
}

.discriptionLabelStyles:hover {
  background-color: white;
  border-color: transparent;
}

.discriptionStyles {
  max-height: 35vh;
  height: 35vh;
  overflow: auto;
}

.containing {
  width: 100%;
  /* height: 400%; Sets the container height to full viewport height */
  margin-top: 2%;
  height: 100%;
  max-height: 35vh;
  height: 35vh;
  overflow: auto;
}

.editor-container {
  width: 100%;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18vh;
  /* Set an appropriate height for the container */
  cursor: pointer;
  color:#fe524a;
}

.dropdownsStyles {
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6); */
  color: black;
}

.dropdownsStyles .MuiSelect-icon {
  color: #fe524a;
}

.addNewOptionStyles {
  color: black;
  border-color: transparent;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  width: 50%;
  font-weight: medium;
  font-size: 14px;
}

.addNewOptionStyles:hover {
  background-color: white;
  border-color: black;
  color: black;
}

.containing-option {
  width: 100%;
  /* height: 400%; Sets the container height to full viewport height */
  /* margin-top: 2%; */
  height: 100%;
  max-height: 20vh;
  height: auto;
  overflow: auto;
}

.inlineStyles {
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6); */
  color: black;
}

.inlineStyles .MuiInputLabel-root {
  font-weight: medium;
  font-size: 14px;
  color: black;
}

.combinedStyles {
  padding: 4px;
  /* text-align: center; */
  border-radius: 4px;
  border-color: transparent;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6); */
  color: "#444343";
  font-weight: 600;
  width: 50%;
  font-size: 15px;
}
.gridAdjustment {
  display: flex;
  align-items: center;
}




/* Tooltip for the Bold button */
.ql-bold:hover::after {
  content: 'Bold';
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.ql-bold:hover::before,
.ql-bold:hover::after {
  z-index: 1;
}

.ql-bold:hover::after {
  opacity: 1;
}

.ql-italic:hover::after{
  content: 'Italic';
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.ql-italic:hover::after,.q1-italic:hover::before{
z-index: 1;
}

.ql-italic:hover::after{
  opacity: 1;
}

.ql-underline:hover::after{
  content: 'Underline';
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.ql-underline:hover::after,.q1-italic:hover::before{
z-index: 1;
}

.ql-underline:hover::after{
  opacity: 1;
}

.ql-link:hover::after{
  content: 'Link';
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.ql-link:hover::after,.q1-link:hover::before{
z-index: 1;
}

.ql-link:hover::after{
  opacity: 1;
}

.ql-list:hover::after{
  content: 'List';
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.ql-list:hover::after,.q1-list:hover::before{
z-index: 1;
}

.ql-list:hover::after{
  opacity: 1;
}

.ql-clean:hover::after{
  content: 'Clear';
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.ql-clean:hover::after,.q1-clean:hover::before{
z-index: 1;
}

.ql-clean:hover::after{
  opacity: 1;
}

.ql-header:hover::after{
  content: 'Heading';
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.ql-header:hover::after,.q1-header:hover::before{
z-index: 1;
}

.ql-header:hover::after{
  opacity: 1;
}