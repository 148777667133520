.configurations-container {
    width: 50% ;
    height: fit-content ;
    box-shadow:  0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); ;
    position: absolute ;
    top: 55% ;
    left: 50% ;
    transform: translate(-50%, -50%) ;
}

.logouploadcss{

    /* width:98%; */
    padding: "0px 0px";
    /* fontSize: "14px", */
    color : rgba(0, 0, 0, 0.6);
    text-transform: capitalize;
    border-color:rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    font-weight: 400;
    font-size: 1rem;
    display:flex;
    justify-content: space-between; 
}
.logouploadcss:hover{
    /* width:98%; */
    padding: "0px 0px";
    /* fontSize: "14px", */
    line-height: 1.75;
    color : rgba(0, 0, 0, 0.6);
    text-transform: capitalize;
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    font-weight: 400;
    font-size: 1rem;
    display:flex;
    justify-content: space-between; 
    background-color: white;
    
}