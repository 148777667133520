
  .mainContainer {
    
    height: auto ;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
 position: absolute ;
    top: 50% ;
    left: 50% ;
    transform: translate(-50%, -50%) ;
    margin-top: 2%;
   
}

  
.button {
   
  font-size:14px ;
  color:#FE524A ;
  border:1px solid #FE524A ;
  border-radius:4px ;
  font-weight:medium ;
  align-items:center ;
  background-color: #FFFFFF ;
}

.button:hover {
  background-color: #FE524A ;
  border-color: #fe524a;
  color : #FFFFFF ;
  cursor: pointer ;
}
button.click:disabled{
  background-color: #d9d9d9;
  color: #4f4f4f;
  font-size: 14px;
  padding: 8px 0px;
  font-weight: medium;
}


.stack-root {
  margin-top: 3%;
  margin-bottom: 3%;

}


.Toastify__toast {

  background-color: #FAEFEF; 

  color:black;

  padding-bottom: 7%;

}