
  
  .login-container {
    width: 30% ;
    height:auto ;
    
    position: absolute ;
    top: 50% ;
    left: 50% ;
    box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    transform: translate(-50%, -50%) ;
    align-items: center;
  
  }

  .login-form-container{
padding-top: 5%;
padding-bottom: 7%;
  }
  
  .login-label {
    color: #fe524a ;
    padding: 4px 10px ;
    font-weight: bold ;
  }
  
  .signIn {
    padding:8px 8px ;
    font-size:14px ;
    color:#FE524A ;
    border:1px solid #FE524A ;
    border-radius:4px ;
    font-weight:medium ;
    align-items:center ;
    background-color: #FFFFFF ;
  }
  
  .signIn:hover {
    background-color: #FE524A ;
    color: #FFFFFF ;
    cursor: pointer ;
  }
  
  .forgot-password-link {
    display: flex ;
    align-items: center ;
  }
  

.login-link {
  text-decoration: underline;
  font-size: small;
  padding-left: 60%;
  color: rgb(254, 82, 74);
}

.input-stack {
  width: 100%;
}

.error-message {
  color: red;
  font-size: 12px;

  text-decoration: underline;
}

.password-message{
  color: red;
  font-size: 12px;
  text-decoration: underline;

}
.password-error-container{
margin-left: 1%;
}

.selected-item {
color: black ;
}
.custom-select .MuiSelect-outlined.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: black;
}

