  .preview-container {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    width: 90%;
    padding: 2%;
  }
  
  .preview-box {
    width: 95%;
    height: auto;
    margin: auto;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 2%;
  }
  
  .options-stack {
    margin-left: 7%;
  }
  .options-stack {
    display: flex;
    flex-direction: column;
  }
  
  .options-stack > div {
    margin-bottom: 8px; /* Adjust the margin as needed */
  }
  
  
  .time-icon {
    color: #fe524a;
  }
  
  .button-stack {
    margin-top: 20px;
  }
  .label {
    color: adadad;
    margin-right: 3px;
    /* padding-top: 0.8%; */
  }