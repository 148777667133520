.preview-title {

    text-align: center;

    padding-top: 2%;

    padding-bottom: 2%;

    font-weight: medium;

    font-size: 40px;

    color: #444343;

}



.preview-container {



    width: 90%;

    padding: 2%;

}



.instruction {

    margin-bottom: 20px;

}



.button-stack {

    margin-top: 20px;

    justify-content: flex-end;

    display: flex;

    gap: 10px;

}

.ok-button {

    color: #fe524a;

    border-color: #fe524a;

    background-color: transparent;

    &:hover {

        background-color: #fe524a;

        color: white;

        align-items: center;

        border-color: #fe524a;

    }

    &:disabled {

        background-color: #d9d9d9;

        color: #4f4f4f;

        cursor: not-allowed;

        box-shadow: none;

    }

   

    font-weight: medium;

    font-size: 18px;

}