.flex {
  display: flex;
  justify-content: space-between;
  /* padding: 8px 16px; */
}
.row {
  width: 100%;
  height: 20px;
  padding: 0px;
  border: 1px solid;
  box-shadow: 2px 5px 2px grey;
}
.heading {
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* margin-top: 25px; */
  text-align: left;
}
.evenFlex1 {
  display: flex;
  justify-content: space-between;
  /* padding: 8px 16px; */
  /* min-height: 20px; */
  background-color: #faefef;
}
.left {
  padding-top: 10px;
  grid-column: 1;
}
.right {
  grid-column: 2;
  gap: 0px;
  justify-content: flex-end;
  float: right;
  align-items: center;
}
.cell {
  width: 140px;
  /* height: 30px; */
  color: #191f1c;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding: 10px; */
}
/* .cell:hover {
  padding: 10px;
  width: 140px;
  height: 30px;
  white-space: pre-wrap
} */
a {
  color: #fe524a;
}
.reverse {
  display: flex;
  float: right;
  margin-top: 10px;
  gap: 20px;
}
.pageBot {
  /* float: inline-end; */
  height: 27px;
  margin-right: 0px;
  width: fit-content;
  border: 1px solid #fe524a;
  width: 25px;
  size: 0.66rem;
  min-width: 34px;
  /* box-sizing: content-box; */
}
.css-1aotht6-MuiButtonBase-root-MuiButton-root {
  min-width: 20px;
}

.field {
  width: 180px;
}
.bot1 {
  height: 30px;
  margin-top: 8px;
  padding: 16px;
  width: fit-content;
  border: 1px solid #fe524a;
}
.bot1:hover {
  background-color: #fe524a;
  color: white;
}
.bot2 {
  height: 30px;
  margin-top: 8px;
  padding: 16px;
  width: fit-content;
  border: 1px solid #fe524a;
}
.bot2:hover {
  background-color: #fe524a;
  color: white;
}
.bot:disabled {
  color: E0E0E0;
  /* background-color: adadad; */
}
.padd {
  margin: 4px;
  padding: 2px;
}
.oddFlex1 {
  display: flex;
  justify-content: space-between;
  /* padding: 8px 16px; */
  /* min-height: 20px; */
}
.typography {
  margin-top: 50px;
  font-weight: 600;
  color: #444343;
  text-align: center;
  font-size: 1.5rem;
}
.cell1 {
  width: 140px;
  /* height: 30px; */
  /* text-align: center; */
  /* padding: 10px; */
  color: #444343;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: grid;
  align-items: center; */
  /* padding-top: 2%; */
}
.cell1:hover {
  /* width: 140px;
  height: 30px; */
  /* padding: 10px; */
  white-space: pre-wrap;
}
/* Button:disabled {
  border: 1px solid #adadad;
} */


.cell2 {
  width: 40px;
  /* height: 30px; */
  text-align: center;
  /* padding: 10px; */
  color: #444343;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cell2:hover {
  /* width: 40px;
  height: 30px;
  padding: 10px; */
  white-space: pre-wrap;
}
