.card {
  width: 80vw;
  margin: auto;
  height: 90%;
  margin-top: 3%;
}
.stack {
  margin: 3%;
}

/* .MuiPickersLayout-contentWrapper .css-1r39cda {
  width: 450px;
  height: 315px;
} */

.chip {
  margin: 4px;
  display: inline-flex;
}
#outer {
  display: flex;
  flex-direction: row;
  margin-bottom: 3%;
  width: 100%;
}

#label {
  color: #444343;
  text-align: center;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  font-size: medium;
}

.textFields {
  width: 100%;
  display: flex;
  margin-left: 1%;
}

#startDate {
  margin-left: 3%;
  float: left;
  display: flex;
  flex-direction: column;
  margin-inline-start: 1rem;
  width: 300px;
}

#endDate {
  float: right;
  margin-right: 1%;
  display: flex;
  flex-direction: column;
}

button.upload-button {
  align-items: center;
  border-radius: 30px;
  height: fit-content;
  display: inline-flex;
}
.upload-button-outer {
  width: 100%;
  display: flex;
  margin-left: 1%;
}

#uploadFileInput {
  display: none;
}
.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}
#dateFields {
  margin-bottom: 3%;
}
/* .MuiIconButton-root svg {
  fill: #fe524a;
} */
.outerButton {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
/* button.sendInvitationButton {
  padding: 8px 16px;
  font-size: 14px;
  color: #fe524a;
  border: 1px solid #fe524a;
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: medium;
  align-items: flex-end;
}
button.sendInvitationButton:hover {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: medium;
  align-items: center;
  color: #ffffff;
  background-color: #fe524a;
  border-bottom: 1px solid rgba(152, 147, 147, 0.57);
  cursor: pointer;
} */
.selectedFile {
  margin-right: 10px;
  padding-top: 6px;
}
.MuiFormHelperText-root {
  color: #de2222 !important;
}
