/* styles.css */



.buttonStyles{
  color: #fe524a;
  border-color:#fe524a;
}

.buttonStyles:hover{
  background-color: #fe524a;
  color: white  ;
  align-items:center;
  border-color: #fe524a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6) ;
  font-weight: bold;
}
