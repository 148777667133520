
  .changepassword-container {
    width: 30% ;
    height: auto ;
    padding-top: 2%;
    padding-bottom: 2%;
    position: absolute ;
    top: 50% ;
    left: 50% ;
    transform: translate(-50%, -50%) ;
    box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    
    transition: transform 0.3s ease ;
  }
  

  .changepassword-form-container{
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .changepassword-title {
    color: #fe524a ;
    padding: 4px 10px ;
    margin-bottom: 10%;
    font-weight: bold ;
  }
  
  
  .confirm_button {
    padding:8px 8px ;
    font-size:14px ;
    color:#FE524A ;
    border:1px solid #FE524A ;
    border-radius:4px ;
    font-weight:medium ;
    align-items:center ;
    background-color: #FFFFFF ;
    margin-top: 10% ;
  }

  
  .confirm_button:hover {
    background-color: #FE524A ;
    color: #FFFFFF ;
    cursor: pointer ;
  }
  .heading1{
  /* styles.css */
  font-size: 1.625rem;
  font-weight: bold;
  text-align: center;
  padding: 2%;

}