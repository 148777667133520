.container {
  height: 95vh;
  z-index: 1;
}
/* .header{
    font-size: 28px ;
     font-weight: bolder;
     text-align:center ;
     color:#191F1C;
} */

.buttonStyle {
  color: #fe524a;
  border-color: #fe524a;
  padding: 4px;
}

.buttonStyle:hover {
  background-color: #fe524a;
  color: #ffffff;
  align-items: center;
  border-color: #fe524a;
  font-weight: bold;
  padding: 4px;
  border-radius: 4px;
  cursor: "pointer"
}

.color-red {
  color: #fe524a;
}

.color-black {
  color: #191f1c;
  display: flex;
  gap: 1.25rem;
}

.stack-question {
  flex-direction: flex;
  margin-top: 1%;
}

.level {
  display: flex;
  margin-left: auto;
  color: #191f1c;
}

.questionCard {
  box-shadow: 5;
  margin-top: 4px;
  padding: 2%;
}

.paginationDisable {
  cursor: no-drop;
  color: #4f4f4f;
}

.paginationEnable {
  cursor: pointer;
  color: #fe524a;
}

.css-m70ye {
  align-items: baseline;
}

.css-343dll {
  align-items: baseline;
}
.questionsOptions p{
  margin: 0;
}

.color-secondary {
  color: #444343;
}

.delete-and-edit{
  border: 1px;
  color:#fe524a;
  min-width: 34px;
  padding: 4px 4px;
}

.infoicon{
  border: none;
  min-width: 34px;
  padding: 4px 4px;
}