/* place global stylings here */
.mainContainer{
   margin-top: 3%;
}
button.csrbutton{
    padding: 8px 16px;
    font-size: 14px;
    color: #FE524A;
    border: 1px solid #FE524A;
    border-radius: 4px;
    background-color: #FFFFFF;
    font-weight:medium;
    align-items: center;
    margin-right: 8px;
    display: flex;
    float: right;
}
button.csrbutton:hover{
    padding: 8px 16px;
    font-size: 14px;
    font-weight:medium;
    color: #FFFFFF;
    align-items: center;
    border: #FE524A;
    background-color: #FE524A;
   
}
.submit{
    margin-top: 8px;
    
}

.boxa{
    display: flex;
}

.boxb{
    margin-left: 25%;
    width: 70%;
    height: 80%;
    margin-top: 8%;
}

.boxc{
    margin-top: 10%;
    width: 30%;
    height: 80%;
}
.Box{
    margin-top: 18px;
    margin-left: 36px;
    margin-bottom: 20px;
    padding: 8px 16px;
    border-radius: 4px;
    

    width: 200px;
}
.bot{
    float: left;
    margin-left: 30%;
    padding-top: 12px;
    padding-left: 8px;
}
.pie{
    border-radius: 0px;
}
.cont{
    display: flex;
}
button.csrbutton{
    margin-right:5%;
    margin-top: 3%;
}
