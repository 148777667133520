.BulkUpload.fileInput {
    display: none;
  }
  .info ul {
    list-style-type: none;
    padding-left: 0;
    margin-left:-16px;
  }
  
  .info ul li {
    margin-left:5px;
  }
  .head{
    margin-left: 395px;
  }

  

  /* .buttonStyles {
  
  font-size: 14px;
  color:rgb(239, 35, 35);
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  top: -10px;
  border: 1px solid #fe524a; 
}

.buttonStyles:hover {
  
  color: whitesmoke;
  background-color: #e95a52;
  align-items: center;
  border-color: #f5534a;
} */
