.rootContainer {


  height: auto ;

  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  position: absolute ;

  top: 50% ;

  left: 50% ;

  transform: translate(-50%, -50%) ;

  margin-top:3.5%;



}





/* th,

td {

  border: 2px solid black;

  padding: 2%;

  word-wrap: break-word; 

} */




.heading3 {

  padding: 2%;

  /* font-size: 1.625rem;

  font-weight: bold;

  text-align: center; */

}




.button {

  font-size: 14px;

  color: #fe524a;

  border: 1px solid #fe524a;

  border-radius: 4px;

  font-weight: medium;

  align-items: center;

  background-color: #ffffff;

}




.button:hover {

  background-color: #fe524a;

  border: 1px solid #fe524a;

  color: #ffffff;

  cursor: pointer;

}




/* .mainContainer {

  width: auto ;

  height: auto ;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6) ;

  position: absolute ;

  top: 50% ;

  left: 50% ;

  transform: translate(-50%, -50%) ;

} */




.buttonContainer {

  display: flex;

  justify-content: flex-end; /* Align buttons to the right */

  margin-top: 10rem; /* Add some spacing from the table */

  margin  : 2%;





}




/* .MuiTableCell-root:not(.MuiTableCell-head),

.MuiTableCell-root.MuiTableCell-head {

  border: none;

} */

/* .tableWrapper {

  flex: 1;

  overflow: auto;

} */

/* .selected-row {

  background-color: #FFCFCD ;

  color: #ffffff ;

} */




/* .table-row-even {

  background-color: #ffffff;

}




.table-row-odd {

  background-color: #FAEFEF;

} */




.stack-root {

  margin-top: 3%;

  margin-bottom: 3%;




}